.title-center {
  h1, h2 {
    text-align: center;
  }
}

.title-h1h2 {
  h1  {
    font-weight: 100;
    strong, bold {
    font-weight: 400;
  }
  }

}

// Typography Hints
.title-h1h2, .title-center {
  h1 + h2 {
    margin-top: -35px;
    margin-bottom: 50px;
    font-weight: 700;
  }
}

a:focus {
  outline: none !important;
}

img {
  max-width: 100%;
}


// Codeblocks
pre:not(.phpdebugbar-widgets-code-block) {
  code {
    background: #f8f8f8;
    display: block;
    padding: 1rem !important;
    line-height: 1.5;
    color: inherit;
    border-radius: 2px;
    overflow-x: auto;
  }
}

pre.xdebug-var-dump {
  background: #f8f8f8;
  display: block;
  padding: 1rem !important;
  line-height: 1.5;
  color: inherit;
  border-radius: 2px;
  overflow-x: auto;
}

// Icon Tweaks
i.fa {


  &.fa-heart, &.fa-heart-o {
    &.pulse {
      color: #920
    }
  }
}

// Font Weights
b,
strong {
  font-weight: 700;
}

.heavy {
  font-weight: 700;
}

.light {
  font-weight: 200;
}

// Colors
.text-light {
  color: rgba($light-color, 0.8);

  h1, h2, h3, h4, h5, h6 {
    color: rgba($light-color, 0.9);
  }
}

// Error configuration
#error {
  text-align: center;
  position: relative;
  margin-top: 5rem;

  .icon {
    font-size: 50px;
  }
}

// Messages
#messages {
  margin-bottom: 1rem;

  .icon {
    font-size: 120%;
  }
}

// Notices
.notices {
  margin: 1.5rem 0;
  p {
    margin: 1rem 0;
  }
}

